@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");

html,
body,
#root {
  margin: 0;

  height: 100%;
  width: 100%;

  overflow-y: auto;

  /* hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  scrollbar-width: thin;
}
/* hide scrollbar for chrome, safari and opera */
#root::-webkit-scrollbar {
  display: none;
}
