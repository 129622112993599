.ownership-input input{
    width: 427px;
    max-width: 427px;
}

.ownership-select input{
    width: 256px;
    max-width: 256px;
}

.ownership-multiline textarea{
    width: 648px;
    max-width: 648px;
}

.ownership-input-root {
    width: fit-content;
    max-width: 648px;
}