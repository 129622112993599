.introBgGradient {
  background: linear-gradient(109.28deg, #082b54 2.2%, #2373bf 103.35%);
}

sup {
  color: red;
}

.customPhoneFieldDropDown {
  font-family: "Poppins";
}

.activationCodeInput{
  font-family: "Poppins"; 
  appearance: textfield;
  width: 56px;
  height: 56px;
  margin: 4px;
  border: 1px solid;
  border-radius: 8px;
  font-size: 16px;
  border-color: #EBEBEB;
  text-align: center;
}
.activationCodeInputInvalid{
  font-family: "Poppins"; 
  appearance: textfield;
  width: 56px;
  height: 56px;
  margin: 4px;
  border: 1px solid red;
  border-radius: 8px;
  font-size: 16px;
  text-align: center;
}
.activationCodeInput:hover{
  border: 1px solid #082b54;
}
.activationCodeInput:focus{
  outline: 2px solid #082b54;
}